<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage hostel resident</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Hostel resident
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn v-if="checkIsAccessible('hostel-resident', 'create')" class="mt-4 btn btn-primary" style="color: #fff" @click="addHostelResident">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add hostel resident
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="4">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        @change="handelHostelChange"
                                        v-model="search.hostel_id"
                                        :items="hostels"
                                        item-text="title"
                                        item-value="id"
                                        label="Search by hostel"
                                        v-on:keyup.enter="getHostelResidents()"
                                        clearable
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        v-model="search.hostel_room_id"
                                        :items="hostelRooms"
                                        item-text="title"
                                        :loading="hostelRoomLoading"
                                        item-value="id"
                                        label="Search by hostel room"
                                        @input="search.hostel_room_id = $event !== null ? $event : ''" 
                                        v-on:keyup.enter="getHostelResidents()"
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                
                                <v-col cols="12" md="4">
                                    <v-btn :loading="loading" @click.prevent="searchHostelRooms()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Hostel info</th>
                                        <th class="px-3 wrap-column">More info</th>
                                        <th class="px-3 wrap-column">Discount info</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="hostelResidents.length > 0" v-for="(item, index) of hostelResidents" :key="index">
                                        <td class="px-3 wrap-column">
                                            <div>
                                                <strong>Hostel:</strong>
                                                {{ item.hostel_name ? item.hostel_name : '-' }}
                                            </div>
                                            <div class="mt-2">
                                                <strong>Hostel room:</strong>
                                                {{ item.hostel_room_name ? item.hostel_room_name : '-' }}
                                            </div>

                                            <div class="mt-2">
                                                <span 
                                                    class="badge text-lg "
                                                    v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                                                    {{ item.is_active ? 'Active' : 'Inactive' }}
                                                </span>
                                            </div>
                                            <hr>
                                            <div class="mt-2 wrap-column ml-2">
                                                <strong>User: </strong>
                                                <span class="wrap-column">{{ item.user_info ? item.user_info : 'NA' }}</span>
                                            </div>
                                        </td>

                                        <td class="px-3 wrap-column">
                                            <div class="mt-2">
                                                <strong> Fee frequency:</strong> {{ item.fee_frequency_formatted ? item.fee_frequency_formatted : '-' }}                                                
                                            </div>
                                            <div class="mt-2">
                                                <strong> Fee:</strong> {{ item.fee ? item.fee : '-' }}                                                
                                            </div>
                                            <div class="mt-2">
                                                <strong> Payment start date:</strong> {{ item.payment_start_date ? item.payment_start_date : '-' }}                                                
                                            </div>

                                            <div class="mt-2 ml-1">
                                                <strong>Dates: </strong>
                                                {{ item.entry_date ? item.entry_date : '' }} to {{ item.exit_date ? item.exit_date : '' }}
                                            </div>
                                        </td>
                                        

                                        <td class="px-3 wrap-column">
                                            <div class="mt-2">
                                                <strong> Has discount:</strong>
                                                <span 
                                                    class="badge text-lg "
                                                    v-bind:class="{ 'badge-success': item.has_discount, 'badge-danger': !item.has_discount }">
                                                    {{ item.has_discount ? 'Yes' : 'No' }}
                                                </span>                                                
                                            </div>
                                            <div class="mt-2">
                                                <strong> Discount type:</strong> {{ item.discount_type_formatted }}                                                
                                            </div>
                                            <div class="mt-2">
                                                <strong> Discount amount:</strong> {{ item.discount_amount ? item.discount_amount : 'NA' }}                                                
                                            </div>
                                        </td>
                                        
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('hostel-resident', 'edit')">
                                                        <a href="#" class="navi-link" @click="editHostelResident(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('hostel-resident', 'delete')">
                                                        <a href="#" class="navi-link" @click="deleteHostelResident(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="hostelResidents.length == 0">
                                        <td class="text-center  px-3" colspan="7">
                                          <strong>No data available to display.</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="hostelResidents.length > 0" 
                                @input="getHostelResidents" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <create-and-update ref="hostel-resident" @refresh="getHostelResidents"></create-and-update>
    </v-app>
</template>

<script>
import HostelRoomService from "@/core/services/hostel/hostel-room/HostelRoomService";
import CreateAndUpdate from "./CreateAndUpdate.vue";
import HostelService from "@/core/services/hostel/HostelService";
import HostelResidentService from "@/core/services/hostel/hostel-resident/HostelResidentService";

const hostel = new HostelService();
const hostelRoom = new HostelRoomService();
const hostelResident = new HostelResidentService();

export default {
    components: {
        CreateAndUpdate
    },
    data() {
        return {
            search: {
                hostel_id: null,
                title: '',
                hostel_room_id: null
            },
            loading: false,
            hostelRooms: [],
            hostelResidents: [],
            page: null,
            perPage: null,
            total: null,
            hostels: [],
            hostelRoomLoading: false,
        }
    },
    methods: {
        searchHostelRooms(){
            this.page = 1;
            this.getHostelResidents();
        },  
        handelHostelChange(){
            if(this.search.hostel_id){
                this.getHostelRooms()
            }else{
                this.hostelRooms = [];
                this.search.hostel_room_id = null;
            }
        },  
        getHostelResidents() {
            this.loading = true;
            hostelResident
                .paginate(this.search, this.page)
                .then(response => {
                    this.hostelResidents = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        addHostelResident() {
            this.$refs["hostel-resident"].showModal();
        },
        editHostelResident(id) {
            this.$refs["hostel-resident"].showModal(id);
        },
        deleteHostelResident(hostelResidentId){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        hostelResident
                        .delete(hostelResidentId)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getHostelResidents();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        getHostels(){
            hostel
            .all()
            .then((res) => {
                this.hostels = res.data.hostels;
            })
            .catch(err => {

            });
        },
        getHostelRooms(){
            this.hostelRoomLoading = true;
            hostelRoom
            .getByHostel(this.search.hostel_id)
            .then((res) => {
                this.hostelRooms = res.data;
                this.hostelRoomLoading = false;
            })
            .catch(err => {

            });
        },
    },
    mounted() {
        this.getHostelResidents();
        this.getHostels();
        this.getHostelRooms();
    }
}
</script>