<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} hostel resident` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cancelForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>                        
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                v-model="hostelResident.hostel_id"
                                outlined
                                dense
                                :items="hostels"
                                item-text="title"
                                item-value="id"
                                @change="handelHostelChange"
                                :error="$v.hostelResident.hostel_id.$error"
                            >
                                <template v-slot:label>
                                    Hostel <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.hostelResident.hostel_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-autocomplete
                                v-model="hostelResident.hostel_room_id"
                                outlined
                                dense
                                :loading="hostelRoomLoading"
                                :items="hostelRooms"
                                item-text="title"
                                item-value="id"
                                :error="$v.hostelResident.hostel_room_id.$error"
                            >
                                <template v-slot:label>
                                    Hostel room<span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.hostelResident.hostel_room_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-autocomplete
                                @change="selectUser"
                                v-model="selectedUser"
                                outlined
                                dense
                                :items="users"
                                hide-selected
                                item-text="display_text"
                                item-value="id"
                                :search-input.sync="userSearch"
                                @input="hostelResident.user_id = $event !== null ? $event : ''; hasHostel=false;" 
                                :loading="isUserLoading"
                                clearable
                                placeholder="Search by user name, email or CRN"
                                :error="$v.hostelResident.user_id.$error || hasHostel"
                            >
                            <template v-slot:label>
                                User <span class="text-danger">*</span> 
                            </template>
                            <template v-slot:no-data>
                                <div class="no-data-auto-complete text-center">No data available </div>
                            </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.hostelResident.user_id.$error">This information is required</span>
                            <span class="text-danger" v-if="hasHostel">The selected student has an active hostel, you cannot assign new hostel until the previous affiliation expires.</span>
                        </v-col>

                        <v-col cols="12" md="6" class="mt-3">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                min-width="auto"
                                offset-y
                                transition="scale-transition"
                                v-model="menu"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    append-icon="mdi-calendar"
                                    clearable
                                    dense
                                    :error="$v.hostelResident.entry_date.$error"
                                    outlined
                                    readonly 
                                    v-bind="attrs"
                                    v-model="hostelResident.entry_date"
                                    v-on="on"
                                >
                                    <template v-slot:label>
                                        Entry date 
                                        <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                @input="menu = false"
                                v-model="hostelResident.entry_date"
                            ></v-date-picker>
                            </v-menu>
                            <span class="text-danger" v-if="$v.hostelResident.entry_date.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6" class="mt-3">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                min-width="auto"
                                offset-y
                                transition="scale-transition"
                                v-model="menu1"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    append-icon="mdi-calendar"
                                    clearable
                                    dense
                                    outlined
                                    readonly 
                                    :error="$v.hostelResident.exit_date.$error"
                                    v-bind="attrs"
                                    v-model="hostelResident.exit_date"
                                    v-on="on"
                                >
                                    <template v-slot:label>
                                        Exit date 
                                        <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                @input="menu1 = false"
                                v-model="hostelResident.exit_date"
                            ></v-date-picker>
                            </v-menu>
                            <span class="text-danger" v-if="$v.hostelResident.exit_date.$error">This information is required</span>
                        </v-col>                        

                        <v-col cols="12" md="6" class="mt-3">
                            <v-select
                                v-model="hostelResident.fee_frequency"
                                outlined
                                dense
                                :items="feeFrequencies"
                                item-text="name"
                                item-value="value"
                                :error="$v.hostelResident.fee_frequency.$error"
                            >
                                <template v-slot:label>
                                    Fee frequency<span class="text-danger">*</span>
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.hostelResident.fee_frequency.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6" class="mt-3">
                            <v-text-field
                                v-model="hostelResident.fee"
                                outlined
                                type="number"
                                dense
                                :error="$v.hostelResident.fee.$error"
                            >
                                <template v-slot:label>
                                    Fee<span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelResident.fee.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12" class="mt-3">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                min-width="auto"
                                offset-y
                                transition="scale-transition"
                                v-model="menu2"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    append-icon="mdi-calendar"
                                    clearable
                                    dense
                                    :error="$v.hostelResident.payment_start_date.$error"
                                    outlined
                                    readonly 
                                    v-bind="attrs"
                                    v-model="hostelResident.payment_start_date"
                                    v-on="on"
                                >
                                    <template v-slot:label>
                                        Payment start date
                                        <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                @input="menu2 = false"
                                v-model="hostelResident.payment_start_date"
                            ></v-date-picker>
                            </v-menu>
                            <span class="text-danger" v-if="$v.hostelResident.payment_start_date.$error">This information is required</span>
                        </v-col> 

                        <v-col cols="12" md="3">
                            <label>
                                Has discount
                            </label>
                            <v-switch 
                                v-model="hostelResident.has_discount" 
                                :label="hostelResident.has_discount ? 'Yes' : 'No'"
                            ></v-switch>
                            <span class="text-danger" v-if="$v.hostelResident.has_discount.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="5" class="mt-5" v-if="hostelResident.has_discount">
                            <v-select
                                v-model="hostelResident.discount_type"
                                outlined
                                dense
                                :items="discountTypes"
                                item-text="name"
                                item-value="value"
                                :error="$v.hostelResident.discount_type.$error"
                            >
                                <template v-slot:label>
                                    Discount type<span class="text-danger">*</span>
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.hostelResident.discount_type.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="4" class="mt-5" v-if="hostelResident.has_discount">
                            <v-text-field
                                v-model="hostelResident.discount_amount"
                                outlined
                                type="number"
                                dense
                                :error="$v.hostelResident.discount_amount.$error"
                            >
                                <template v-slot:label>
                                    Discount amount<span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelResident.discount_amount.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Status
                            </label>
                            <v-switch v-model="hostelResident.is_active" :label="hostelResident.is_active ? 'Active' : 'Inactive'"></v-switch>
                            <span class="text-danger" v-if="$v.hostelResident.is_active.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn 
                                    depressed 
                                    @click="cancelForm" 
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <!-- <v-btn 
                                    v-if="checkIsAccessible('gate-pass', 'create')" 
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                                <v-btn 
                                    depressed 
                                    :disabled="hasHostel"
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                    v-if="checkIsAccessible('hostel-resident', 'create') && checkIsAccessible('hostel-resident', 'edit')"
                                >
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import HostelService from "@/core/services/hostel/HostelService";
import HostelRoomService from "@/core/services/hostel/hostel-room/HostelRoomService";
import HostelResidentService from "@/core/services/hostel/hostel-resident/HostelResidentService";
import UserService from "@/core/services/user/UserService";

const hostel = new HostelService();
const hostelRoom = new HostelRoomService();
const hostelResident = new HostelResidentService();
const user = new UserService();

export default {
    validations: {
        hostelResident: {
            hostel_id: {required},
            hostel_room_id: {required},
            user_id: {required},
            entry_date: {required},
            exit_date: {required},
            fee:{required},
            fee_frequency: {required},
            payment_start_date: {required},
            has_discount: {required},
            discount_type: requiredIf(function (){
                return this.hostelResident.has_discount;
            }),
            discount_amount: requiredIf(function (){
                return this.hostelResident.has_discount;
            }),
            is_active: {}
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            isUserLoading: false,
            menu: false,
            menu1: false,
            menu2: false,
            hostelResident: {
                hostel_id: null,
                hostel_room_id: null,
                user_id: null,
                entry_date: null,
                exit_date: null,
                fee: null,
                fee_frequency: '',
                payment_start_date: null,
                has_discount: false,
                discount_type: '',
                discount_amount: null,
                is_active: true
            },
            hostels: [],
            hostelRooms: [],
            feeFrequencies: [
                {
                    name: 'Weekly',
                    value: 'weekly',
                },
                {
                    name: 'Monthly',
                    value: 'monthly',
                },
                {
                    name: 'Quarterly',
                    value: 'quarterly',
                },
                {
                    name: 'Yearly',
                    value: 'yearly',
                },
            ],
            discountTypes:[
                {
                    name: 'Fixed',
                    value: 'fixed'
                },
                {
                    name: 'Percentage',
                    value: 'percentage',
                },
            ],
            users:[],
            userSearch: '',
            hostelRoomLoading : false,
            hasHostel: false,
            initialUserId: null,
        };
    },
    methods: {
        handelHostelChange(){
            if(this.hostelResident.hostel_id){
                this.getHostelRooms()
            }
        },  
        cancelForm(){
            this.resetForm();
            this.hideModal();
        },
        showModal(id = null) {
            this.getHostels();
            if (id) {
                this.edit = true
                this.getHostelResident(id)
            }else{
                this.resetForm();
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        getHostelResident(id) {
            hostelResident
            .show(id)
            .then(response => {
                this.hostelResident = response.data.hostelResident;
                this.getHostelRooms();
                if(this.hostelResident.user_id){
                    this.initialUserId = this.hostelResident.user_id;
                    this.searchUser(this.hostelResident.user_email)
                }
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.hostelResident.$touch();
            if (this.$v.hostelResident.$error) {
                setTimeout(() => {
                    this.$v.hostelResident.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateHostelResident();
                } else {
                    this.createHostelResident();
                }
            }
        },
        updateHostelResident() {
            this.isBusy = true;
            hostelResident
            .update(this.hostelResident.id, this.hostelResident)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
                this.hideModal();
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createHostelResident() {
            this.isBusy = true;
            hostelResident
            .store(this.hostelResident)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
                this.hideModal();
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.hostelResident.$reset();
            this.edit = false;
            this.hostelResident = {
                hostel_id: null,
                hostel_room_id: null,
                user_id: null,
                entry_date: null,
                exit_date: null,
                fee: null,
                fee_frequency: '',
                payment_start_date: null,
                has_discount: false,
                discount_type: '',
                discount_amount: null,
                is_active: true
            };

            this.users = [];
            this.hostels = [];
            this.selectedUser = null;
            this.hostelRooms = [];
            this.userSearch = '';
            this.hostelRoomLoading = false;
            this.initialUserId = null;
        },
        getHostels(){
            hostel
            .all()
            .then((res) => {
                this.hostels = res.data.hostels;
            })
            .catch(err => {

            });
        },
        getHostelRooms(){
            this.hostelRoomLoading = true;
            hostelRoom
            .getByHostel(this.hostelResident.hostel_id)
            .then((res) => {
                this.hostelRooms = res.data;
                this.hostelRoomLoading = false;
            })
            .catch(err => {

            });
        },
        selectUser()
        {
            if(this.selectedUser && this.selectedUser != null){
                if(this.initialUserId != this.selectedUser){
                    this.checkIfUserHasHostel(this.selectedUser);
                }
                this.hostelResident.user_id = this.selectedUser;
            }else{
                this.hostelResident.user_id = "";
            }
        },
        selectedUser(val)
        {
            if(!val){
                this.hostelResident.user_id ='';
            }
        },
        searchUser(val){
            this.isUserLoading = true;
            
            let data = {
                val: val,
                type: 'student'
            };

            user
            .filter(data)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id + 
                        " | " +
                        user.email
                    this.users.push(data);
                });
                this.selectedUser = response.data.users[0];
            })
            .catch((err) => {

            })
            .finally(() => (this.isUserLoading = false));
        },
        checkIfUserHasHostel(userId){
            hostelResident
            .findByStudent(userId)
            .then((res) => {
                if(res.data.status == 'OK'){
                    this.hasHostel = true;
                }else{
                    this.hasHostel = false;
                }
            })
            .catch((err) => {

            })
        }
    },
    watch:{
        userSearch(val) {
            this.isUserLoading = true;
            
            let data = {
                val: val,
                type: 'student'
            };

            user
            .filter(data)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id + 
                        " | " +
                        user.email
                    this.users.push(data);
                });
            })
            .catch((err) => {
            })
            .finally(() => (this.isUserLoading = false));
        }
    }
}
</script>
